import { required } from 'vee-validate/dist/rules';
import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import getActionsBuilder from '@/graphql/workflow/queries/getActionsBuilder.graphql';
import fetchKnownAttributes from '@/graphql/workflow/queries/fetchKnownAttributes.graphql';
import fetchTableAttributes from '@/graphql/workflow/queries/fetchTableAttributes.graphql';
import postKnownAttribute from '@/graphql/workflow/mutations/postKnownAttribute.graphql';
import postAttribute from '@/graphql/workflow/mutations/postNodeAttribute.graphql';
import putAttribute from '@/graphql/workflow/mutations/putNodeAttribute.graphql';
import putKnownAttribute from '@/graphql/workflow/mutations/putKnownAttribute.graphql';
import postJobFormsResponse from '@/graphql/workflow/mutations/postJobFormsResponse.graphql';
import metadataApolloClient from '@/lib/appsync/metadata';
import accountModule from '@/store/modules/accountModule';
import userPreferenceDB from '@/lib/userPreference';
import { getNameByEmail } from '@/utils/users';
import workflowApolloClient from '@/lib/appsync/workflow';
import tasqsListModule from '@/store/modules/tasqsListModule';
import isOnline from 'is-online';
import axiosClient from '@/lib/rest/axiosClient';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'metaDataModule',
  store,
})
class MetaDataModule extends VuexModule {
  knownAttributes: any = []

  datalists: any = []

  requiredDatalist: any = []

  formBuilderData: any = []

  waitingOnList: any = []

  blockedReasonOptions: string[] = []

  sentryInitialize = false

  @Mutation
  setKnowAttributes(data): void {
    this.knownAttributes = data;
  }

  @Mutation
  setSentryState(data): void {
    this.sentryInitialize = data;
  }

  @Mutation
  setDatalists(data): void {
    this.datalists = data;
  }

  @Mutation
  setRequiredDataists(data): void {
    this.requiredDatalist = data;
  }

  @Mutation
  setFormBuilderData(data): void {
    this.formBuilderData = data;
  }

  @Mutation
  setWaitingOnList(data): void {
    this.waitingOnList = data;
  }


  @Mutation
  setBlockedReasonList(data): void {
    this.blockedReasonOptions = data;
  }

  @Action
  async fetchKnownAttributes() {
    try {
      const {
        data: {
          fetch_known_attributes: knownAttributes,
        },
		  } : any = await metadataApolloClient.query({
        query: fetchKnownAttributes,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },

        },
        fetchPolicy: 'network-only',
		  });

      if (knownAttributes && JSON.parse(knownAttributes.Results)) {
        this.setKnowAttributes(JSON.parse(knownAttributes.Results));
        return JSON.parse(knownAttributes.Results);
      }
      return [];

    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async postKnownAttributes({ name, type }) {
    try {
      const {
        data: {
          post_known_attributes: knownAttributes,
        },
		  } : any = await metadataApolloClient.mutate({
        mutation: postKnownAttribute,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Name: name,
            AttributeType: type,
          },

        },
		  });
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async putKnownAttributes() {
    try {
      const {
        data: {
          put_known_attributes: knownAttributes,
        },
		  } : any = await metadataApolloClient.mutate({
        mutation: putKnownAttribute,
        variables: {
          input: {

            Name: 'Test Know 5',
            ID: 2,
            AttributeType: 'VARCHAR',
          },
        },
		  });

      console.log(knownAttributes);
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async putNodeAttribute({ id, val }) {
    try {
      const {
        data: {
          put_attribute: attribute,
        },
		  } : any = await metadataApolloClient.mutate({
        mutation: putAttribute,
        variables: {
          input: {
            ID: id,
            Val: val,
            AttributeType: 'TEXT',

          },

        },
		  });

      console.log(attribute);
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async postNodeAttribute({
    node, attributeId, val, addToJobCommentDetails = { WorkflowTaskID: '', PredictionType: '' },
  }) {
    try {
      const addToJobComment: any = addToJobCommentDetails;
      // ts-ignore
      // eslint-disable-next-line no-param-reassign
      addToJobComment.Username = accountModule.user.email.toLowerCase();
      // eslint-disable-next-line no-param-reassign
      addToJobComment.Title = '';
      addToJobComment.Comment = '';

      const {
        data: {
          post_attribute: attribute,
        },
		  } : any = await metadataApolloClient.mutate({
        mutation: postAttribute,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Node: node,
            KnownAttributeID: attributeId,
            Val: val,
            AttributeType: 'TEXT',
            ...(addToJobComment.WorkflowTaskID) && { AddToJobCommentDetails: addToJobComment },
          },

        },
		  });

      // console.log(attribute);
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async fetchTableAttributes({ node = '', attributeID = '', knownAttributeID = '0' }) {
    try {
      const {
        data: {
          fetch_table_attributes: knownAttributes,
        },
		  } : any = await metadataApolloClient.query({
        query: fetchTableAttributes,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Node: node,
            KnownAttributeID: knownAttributeID,
          },

        },
        fetchPolicy: 'network-only',
		  });

      // console.log('fetchTable')
      // console.log(knownAttributeID)
      // console.log(node)
      // console.log(JSON.parse(knownAttributes.Results))

      if (knownAttributes && knownAttributes.Results && JSON.parse(knownAttributes.Results)) {
        const returnResult = JSON.parse(knownAttributes.Results);
        // console.log( returnResult)
        // console.log(knownAttributeID)
        const returnVal = returnResult.filter((r) => `${r.KnownAttributeID}` == knownAttributeID);
        // console.log(returnVal)
        return returnVal && returnVal.length ? returnVal[0] : {};
      }
      return {};

    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async fetchTableAttributesForFeedback({ node = '' }) {
    try {
      if (navigator.onLine) {
        const {
          data: {
            fetch_table_attributes: knownAttributes,
          },
		  } : any = await metadataApolloClient.query({
          query: fetchTableAttributes,
          variables: {
            input: {
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),
              Node: node,

            },

          },
          fetchPolicy: 'network-only',
		  });

        if (knownAttributes && knownAttributes.Results && JSON.parse(knownAttributes.Results)) {
          const returnResult = JSON.parse(knownAttributes.Results);
          // console.log(returnResult);
          // console.log(knownAttributeID)
          const returnVal = returnResult;
          // console.log(returnVal)

          if (tasqsListModule.enablePreloading) {
            await userPreferenceDB.setItem(`fetchTableAttributesForFeedback:${node}`, returnVal);
          }
          // console.log('savedAttr');
          // console.log(returnVal);

          return returnVal;
        }
        return [];
      }
      const result = await userPreferenceDB.getItem(`fetchTableAttributesForFeedback:${node}`);
      if (result) {
        return result;
      }
      return [];
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async postActionBuilder(input: any) {
    try {
      const {
        title, fields, category, artificialLiftTypes, description, showLatestResponseOnWell, dateOfDisplay,
      } = input;
      // return;

      await axiosClient.post('/actions_form/',{
        name: title,
        category: category,
        artificial_lift_types: artificialLiftTypes,
        description: description,
        show_latest_response_on_well: showLatestResponseOnWell,
        fields: fields,
        date_of_display: dateOfDisplay,
        lifetime_count: 0,

      })
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async putActionBuilder(input: any) {
    try {
      const {
        id, title, fields, category, artificialLiftTypes, description, showLatestResponseOnWell, dateOfDisplay,
      } = input;

      await axiosClient.put(`/actions_form/${id}`,{
          name: title,
          category: category,
          artificial_lift_types: artificialLiftTypes,
          description: description,
          show_latest_response_on_well: showLatestResponseOnWell,
          last_thirty_days_count: 0,
          lifetime_count: 0,
          date_of_display: dateOfDisplay,
          fields: fields,


      })
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async deleteActionBuilder({ id }) {
    try {

      await axiosClient.delete(`/actions_form/${id}`)
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async getActionsBuilder(input: any = { useExisting: false }) {
    try {
      const { useExisting } = input;
      if (useExisting && this.formBuilderData && this.formBuilderData) {
        console.log('usingExisting form builder');
        return;
      }



      const isOnlineApp = await isOnline();
      if (isOnlineApp) {
        const {data} = await  axiosClient.get('/actions_form/')

        if (data && data.length) {
        // console.log(JSON.parse(actions.Results));
          const returnResponse = data

          const response = returnResponse.map((form) => ({
            artificialLiftTypes: form.artificial_lift_types ? form.artificial_lift_types : form.artificial_lift_types,
            dateOfDisplay: form.date_of_display ? (form.date_of_display) : {},
            fields: form.fields,
            category: form.category,
            createdAt: form._event_time,
            description: form.description,
            id: form._id,
            updatedBy: getNameByEmail(form.last_modified_by) || 'Unassign',
            lastThirtyDaysCount: form.last_thirty_days_count,
            lifeTimeCount: form.lifetime_count,
            updatedAt: new Date(form.modified_time).toDateString(),
            title: form.name,
            operatorID: form.operator_id,
            showLatestResponseOnWell: form.show_latest_response_on_well,
          }));

          this.setFormBuilderData(response);
          await userPreferenceDB.setItem('getActionsBuilder', response);
          return response;
        }
      } else {
        const result = await userPreferenceDB.getItem('getActionsBuilder');

        if (result) {
          this.setFormBuilderData(result);
          return result;
        }
      }

      return [];

    //   }
    } catch (e) {
      console.error(e.message);
      const result = await userPreferenceDB.getItem('getActionsBuilder');

      if (result) {
        this.setFormBuilderData(result);
        return result;
      }

      // throw new Error(e);
    }
  }

  @Action
  async getActionsBuilderRecords({ actionBuilderID }) {
    try {
      const isOnlineApp = await isOnline();
      if (isOnlineApp) {
        const {
          data: {
            get_actions_builder: {
              Results: actions,
            },
          },
		  } : any = await metadataApolloClient.query({
          query: getActionsBuilder,
          variables: {
            input: {
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),
              ActionsBuilderID: actionBuilderID,

            },

          },
          fetchPolicy: 'network-only',
		  });

        // console.log(actions);
        if (actions && JSON.parse(actions)) {
          const result = JSON.parse(actions);

          if (result && result.length) {
            const mappedResult = result.map((r) => ({
              ID: r.ID, Name: r.Name, Category: r.Category, dateOfDisplay: typeof r.DateOfDisplay === 'string' ? JSON.parse(r.DateOfDisplay) : {}, actionPayload: JSON.parse(r.Result),
            }));

            if (mappedResult && mappedResult[0]) {
              await userPreferenceDB.setItem(`getActionsBuilderRecords:${actionBuilderID}`, mappedResult[0]);
            }
            return mappedResult[0];
          }
        }
      } else {
        const result = await userPreferenceDB.getItem(`getActionsBuilderRecords:${actionBuilderID}`);
        if (result) {
          return result;
        }
      }

      return {};

    //   }
    } catch (e) {
      console.log(e.message);
      return {};
    }
  }

  // DatalistEndpoints

  @Action
  async postDatalist({ title, options }) {


    try {

      await axiosClient.post('/datalist/',{
        title: title,
       items: options,
       required: false,
      })


      // const {
      //   data: {
      //     post_actions_builder: attribute,
      //   },
		  // } :any = await metadataApolloClient.mutate({
      //   mutation: postDatalist,
      //   variables: {
      //     input: {
      //       Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      //       ListItems: JSON.stringify(options),
      //       ListName: title,
      //       LastModifiedBy: accountModule.user.email.toLowerCase(),

      //     },

      //   },
		  // });

      // console.log(attribute);
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async getDatalist(id: any = null) {
    try {
      if (navigator.onLine) {

         let path = '/datalist/'
         if(id){
          path = path+`?_id=${id}`
         }
         const {data} = await axiosClient.get(path)

        if (data && data.length) {
          if (id === null) {
            const datalist = data.map((item) => ({
              title: item.title,
              updatedAt: new Date(item.date_modified).toDateString(),
              id: item._id,
              required: item.required,
              formCount: 0,
              recordType: item.ecord_type,
              updatedBy: item.last_modified_by ? getNameByEmail(item.last_modified_by) || 'Unassign' : '',
            })).filter((row) => row.required !== true);

            const requiredDataList = data.map((item) => ({
              title: item.title,
              id: item._id,
              required: item.required,
            })).filter((row) => row.required === true);

            this.setDatalists(datalist);

            this.setRequiredDataists(requiredDataList);
          }

          if (tasqsListModule.enablePreloading) {
            await userPreferenceDB.setItem('getDatalist', data);
          }

          return data;
        }
        return [];
      }

      const result: any =  await userPreferenceDB.getItem('getDatalist');

      if (result && result.length) {
        return result;
      }
      return [];
    //   }
    } catch (e) {
      const result: any = await userPreferenceDB.getItem('getDatalist');
      if (result && result.length) {
        return result;
      }
      console.log(e);
    }
  }

  @Action
  async deleteDatalist({ id }) {
    try {
      return await axiosClient.delete(`/datalist/${id}`)
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async putDatalist(input: any) {
    try {

      const {
        id, title, listItems,required
      } = input;
      await axiosClient.put(`/datalist/${id}`,{
        title: title,
        items: listItems,
        required: required,

      })

      // const {
      //   data: {
      //     put_actions_builder: attribute,
      //   },
		  // } : any = await metadataApolloClient.mutate({
      //   mutation: putDatalist,
      //   variables: {
      //     input: {
      //       Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      //       UpdateParent: {
      //         Name: title,
      //       },
      //       ListParentID: id,
      //       UpdateListItems: JSON.stringify(listItems),
      //       LastModifiedBy: accountModule.user.email.toLowerCase(),

      //     },

      //   },
		  // });

      // console.log(attribute);
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }

  // Job forms response

  @Action
  async postJobFormsResponse({
    predictionID, workflowTaskID, formResponse, completeForToday, closeTasq, nodeID, validationResponse,
  }) {
    let formatedFormResponse: any = {};
    if (formResponse && formResponse.length) {
      // eslint-disable-next-line no-param-reassign
      formatedFormResponse = formResponse.map((form) => {
        const formReturnObj = { ...form };
        delete formReturnObj.updatedAt;
        delete formReturnObj.updatedBy;
        delete formReturnObj.operatorID;
        delete formReturnObj.lifeTimeCount;
        delete formReturnObj.lastThirtyDaysCount;
        delete formReturnObj.buildDetailsUrl;
        delete formReturnObj.createdAt;
        formReturnObj.formTime = new Date().toISOString();
        if (formReturnObj && formReturnObj.fields) {
          if (form.fields && form.dateOfDisplay && form.dateOfDisplay.id) {
            const dateOfDisplayField = form.fields.find((field) => field.id === form.dateOfDisplay.id);
            if (dateOfDisplayField && dateOfDisplayField.localVal) {
              formReturnObj.formTime = new Date(dateOfDisplayField.localVal).toISOString();
            }
          }

          // eslint-disable-next-line no-return-assign, no-param-reassign
          formReturnObj.fields = form.fields.map((field) => {
            const fieldReturnObj = field;
            delete fieldReturnObj.visibilityRules;
            delete fieldReturnObj.options;
            delete fieldReturnObj.description;
            delete fieldReturnObj.showDetails;
            delete fieldReturnObj.format;
            return fieldReturnObj;
          });
        } else {
          formReturnObj.fields = [];
        }
        // console.log(formReturnObj);
        return formReturnObj;
      });
    }

    // console.log(formatedFormResponse);

    // return;

    try {
      const {
        data: {
          post_job_form_response: knownAttributes,
        },
		  } : any = await workflowApolloClient.mutate({
        mutation: postJobFormsResponse,
        variables: {
          input: {
            PredictionID: predictionID,
            WorkflowTaskID: workflowTaskID,
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            FormsResponse: JSON.stringify(formatedFormResponse),
            ValidationResponse: JSON.stringify(validationResponse),
            CompletedForToday: completeForToday,
            Username: accountModule.user.email.toLowerCase(),
            CloseTasq: closeTasq,
            NodeID: nodeID,
          },

        },
		  });

      // console.log(knownAttributes);
    //   }
    } catch (e) {
      throw new Error(e);
    }
  }
}

export default getModule(MetaDataModule);
